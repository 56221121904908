import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { Carousel } from 'react-responsive-carousel';
import { money } from '~/common/utils/formatters';
import { Loading } from '@ui-elements';
import { AccessoryPacksCarousel } from '../accessory-packs-carousel';
import { Accessory } from '~/common/models';
import { ModelAccessoriesPage, ModelAccessoryPacksQuery } from '../../store';
import styles from './index.module.scss';

export const AccessoryPackDetailsPage = React.memo(() => {
  const [{ accessoryPack, pimIdentifier, routeModelName }] =
    useRecoilState(ModelAccessoriesPage);
  const accessoryPacks = useRecoilValueLoadable(
    ModelAccessoryPacksQuery(pimIdentifier)
  );

  const videoRef = useRef<ReactPlayer>(null);
  const [isPlayVideo, setPlayVideo] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);
  useEffect(() => {
    if (!!accessoryPack) {
      const carouselImages: string[] = [];

      if (
        !!accessoryPack.imageSrc &&
        !accessoryPack.imageSrc?.includes('photo-not-avail')
      ) {
        carouselImages.push(accessoryPack.imageSrc);
      }

      if (
        !!accessoryPack.detailImageSrc &&
        !accessoryPack.detailImageSrc?.includes('photo-not-avail')
      ) {
        carouselImages.push(accessoryPack.detailImageSrc);
      }

      if (
        !!accessoryPack.image360Src &&
        !accessoryPack.image360Src?.includes('photo-not-avail')
      ) {
        carouselImages.push(accessoryPack.image360Src);
      }

      accessoryPack.accessories.forEach((acc) => {
        carouselImages.push(acc.imageSrc);
      });

      setImages(carouselImages);
    }
  }, [accessoryPack]);

  const isAccessoryBundle = React.useMemo(
    () => accessoryPack?.isBundle ?? false,
    [accessoryPack]
  );

  const handleVideoEnded = () => {
    setPlayVideo(false);
    if (!!videoRef.current) {
      videoRef.current.seekTo(0);
    }
  };

  const getRelatedAccessoryPacks = () => {
    const accPacks = accessoryPacks.getValue();
    return accPacks.filter((x) => x.itemCode !== accessoryPack?.itemCode);
  };

  const formatAccessoryName = (name: string) => {
    return name.replace(/\s+/g, '-').toLowerCase();
  };

  const getAccessoryLink = (accessory: Accessory) => {
    if (!!accessory.accessoryCategoryName) {
      const formattedAccName = formatAccessoryName(accessory.description);
      const formattedAccCategory = formatAccessoryName(
        accessory.accessoryCategoryName
      );
      return `/${routeModelName}/accessories/${formattedAccCategory}/${formattedAccName}`;
    }
  };

  return (
    <div className={styles.accessoryPackPage}>
      <div className={styles.accessoryPackContent}>
        <div className={styles.imageWrapper}>
          <Carousel
            autoPlay={false}
            swipeable={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            showIndicators={false}
            className={styles.accessoryImageCarousel}
          >
            {images.map((img, index) => (
              <img
                key={index}
                className={styles.imageSrc}
                alt={accessoryPack?.description}
                src={img}
              />
            ))}
          </Carousel>

          <div className={styles.accpackNameAndPrice}>
            <h1>{accessoryPack?.description}</h1>
            <p>
              <span>-</span> Fitted RRP:{' '}
              {money(accessoryPack?.specialPriceFitted || 0)}
            </p>
          </div>
        </div>
        <div className={styles.detailsWrapper}>
          <div>
            <h2 className={styles.description}>{accessoryPack?.description}</h2>
            <p className={styles.price}>
              Fitted RRP: {money(accessoryPack?.specialPriceFitted || 0)}
            </p>
            <ul className={styles.accessories}>
              {accessoryPack?.accessories?.map((acc) => (
                <li key={acc.id}>
                  {isAccessoryBundle ? (
                    <p>{acc.description}</p>
                  ) : (
                    <a
                      href={getAccessoryLink(acc)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {acc.description}
                    </a>
                  )}
                </li>
              ))}
            </ul>

            {!isAccessoryBundle ? (
              <>
                <div
                  className={styles.longDescription}
                  dangerouslySetInnerHTML={{
                    __html: accessoryPack?.longDescription || '',
                  }}
                />
                <div
                  className={styles.disclaimer}
                  dangerouslySetInnerHTML={{
                    __html: accessoryPack?.tooltipDisclaimer || '',
                  }}
                />
                <div
                  className={styles.disclaimer}
                  dangerouslySetInnerHTML={{
                    __html: accessoryPack?.longDisclaimer || '',
                  }}
                />
              </>
            ) : (
              <>
                <div
                  className={styles.longDescription}
                  dangerouslySetInnerHTML={{
                    __html: accessoryPack?.longDescription || '',
                  }}
                />
                <div
                  className={styles.bundleLongDisclaimer}
                  dangerouslySetInnerHTML={{
                    __html: accessoryPack?.longDisclaimer || '',
                  }}
                />
                <div
                  className={styles.disclaimer}
                  dangerouslySetInnerHTML={{
                    __html: accessoryPack?.tooltipDisclaimer || '',
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {accessoryPack && !!accessoryPack.videoSrc && (
        <div className={styles.accessoryVideo}>
          <ReactPlayer
            width="100%"
            height="100%"
            pip={false}
            ref={videoRef}
            className={styles.videoContent}
            url={accessoryPack.videoSrc}
            onEnded={handleVideoEnded}
            playing={isPlayVideo}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  disablekb: 1,
                  autoplay: 0,
                  controls: 0,
                  modestbranding: 1,
                },
              },
            }}
          />
        </div>
      )}

      <div className={styles.accessoryPackRelatedProducts}>
        <p className={styles.relatedProductsTitle}>Related Products</p>
        {accessoryPacks.state === 'loading' ? (
          <Loading />
        ) : accessoryPacks.state === 'hasError' ? (
          <div>ERROR</div>
        ) : (
          <div>
            <AccessoryPacksCarousel
              accessoryPacks={getRelatedAccessoryPacks()}
              cardsColor="#F2F3F4"
            />
          </div>
        )}
      </div>
    </div>
  );
});
