import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { SvgIcon, Breadcrumbs } from '@ui-elements';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import { ModelAccessoriesPage, AccessoryRouteBreadcrumbs } from '../../store';
import styles from './index.module.scss';

const defaultRoutes = (name: string, url: string) => [
  {
    icon: (
      <SvgIcon
        type="home"
        size={1.1}
        fill="000"
        style={{ marginTop: '-5px' }}
      />
    ),
    label: 'Showroom',
    path: '/showroom',
    onClick: (path: string) => {
      window.location.replace(path);
    },
  },
  {
    label: `${name} range`,
    path: `/${url}`,
    onClick: (path: string) => {
      window.location.replace(path);
    },
  },
];

export const AccessoriesBreadcrumb = React.memo(() => {
  const [state] = useRecoilState(ModelAccessoriesPage);
  const [breadcrumbsState] = useRecoilState(AccessoryRouteBreadcrumbs);
  const { isDesktop } = useIsMobile();

  const accessoryName = (name: string) => {
    return name
      .replace(/-/g, ' ')
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  // useEffect(() => {
  //   const routes = defaultRoutes(state.modelName, state.parentUrlSegment);
  //   if (breadcrumbsState.length > 0) {
  //     setBreadcrumbs([...routes, ...breadcrumbsState]);
  //   } else {
  //     setBreadcrumbs([...routes, ...[{ label: 'Accessories', path: '/' }]]);
  //   }
  // }, [breadcrumbsState]);

  return (
    <div className={styles.accessoriesTopNav}>
      <div className={isDesktop() ? 'container' : ''}>
        <div className={styles.accessoriesNavContent}>
          {state.breadcrumbs.length >= 1 && (
            <div className={styles.accessoriesBreadcrumbs}>
              <Breadcrumbs breadcrumbs={state.breadcrumbs} />
            </div>
          )}
          <div className={styles.modelBadge}>
            <img src={state.modelBadgeUrl} alt={state.modelName} />
          </div>
        </div>
      </div>
    </div>
  );
});
