import React from 'react';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { PageLayout } from '~/common/components';
import { Breadcrumb } from '~/common/models';
import { AccessoriesMainPage } from './components/accessory-main-page';
import { AccessoryDetailsPage } from './components/accessory-details-page';
import { AccessoryPackDetailsPage } from './components/accessory-packs-details-page';
import { AccessoriesBreadcrumb } from './components/accessory-breadcrumbs';
import { StaticRouter, BrowserRouter, Switch, Route } from 'react-router-dom';
import { ModelAccessoriesPage } from './store';

interface RootComponentProps {
  context: any;
  location: string;
  url: string;
  heading: string;
  subHeading: string;
  accessoryPackTitleOverride?: string;
  parentUrlSegment: string;
  pimIdentifier: string;
  modelName: string;
  modelBadgeUrl: string;
  breadcrumbs: Breadcrumb[];
  routeModelName: string;
}

export const RootComponent = (props: RootComponentProps) => {
  const initialize = ({ set }: MutableSnapshot) => {
    set(ModelAccessoriesPage, { ...props });
  };

  const app = (
    <RecoilRoot initializeState={initialize}>
      <PageLayout>
        <AccessoriesBreadcrumb />
        <Switch>
          <Route
            path="/accessories/:accCategory/:accName"
            component={AccessoryDetailsPage}
          />
          <Route
            path="/accessory-packs/:itemCode/:accPack"
            component={AccessoryPackDetailsPage}
          />
          <Route path="/accessories" component={AccessoriesMainPage} />
        </Switch>
      </PageLayout>
    </RecoilRoot>
  );

  if (typeof window === 'undefined') {
    return (
      <StaticRouter
        basename={`/${props.parentUrlSegment}`}
        context={props.context}
        location={props.location}
      >
        {app}
      </StaticRouter>
    );
  }

  return (
    <BrowserRouter basename={`/${props.parentUrlSegment}`}>{app}</BrowserRouter>
  );
};
