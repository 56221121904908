import React, { useEffect, useState } from 'react';
import { Loading, TabButton } from '@ui-elements';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useRecoilRefresher_UNSTABLE,
} from 'recoil';
import {
  ModelAccessoriesPage,
  ModelAccessoryPacksQuery,
  ModelAccessoriesQuery,
  AccessoryRouteBreadcrumbs,
} from '../../store';
import { AccessoryPacksCarousel } from '../accessory-packs-carousel';
import { AccessoryCard } from '../accessory-card';
import { Accessory } from '~/common/models';
import styles from './index.module.scss';

interface GroupAccessories {
  accessoryCategoryName: string;
  items: Accessory[];
}

export const AccessoriesMainPage = React.memo(() => {
  const [state] = useRecoilState(ModelAccessoriesPage);
  const [_, setBreadcrumbs] = useRecoilState(AccessoryRouteBreadcrumbs);
  const accessoryPacksLoadable = useRecoilValueLoadable(
    ModelAccessoryPacksQuery(state.pimIdentifier)
  );
  const accessoriesLoadable = useRecoilValueLoadable(
    ModelAccessoriesQuery(state.pimIdentifier)
  );

  const refreshAccessoryPacks = useRecoilRefresher_UNSTABLE(
    ModelAccessoryPacksQuery(state.pimIdentifier)
  );
  const refreshAccessories = useRecoilRefresher_UNSTABLE(
    ModelAccessoriesQuery(state.pimIdentifier)
  );

  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [groupAccessories, setGroupAccessories] = useState<
    GroupAccessories[] | undefined
  >();

  useEffect(() => setBreadcrumbs([]), []);
  useEffect(() => {
    if (accessoriesLoadable.state === 'hasValue') {
      const allAccessories = accessoriesLoadable.getValue();
      const groupCategories = groupByDistinctValue(allAccessories);

      setGroupAccessories(
        [
          { accessoryCategoryName: 'All', items: allAccessories },
          ...groupCategories,
        ].filter((x) => x.accessoryCategoryName !== 'After Market')
      );
    }
  }, [accessoriesLoadable.state]);

  const handleSelectCategory = (groupAcc: GroupAccessories) => {
    setSelectedCategory(groupAcc.accessoryCategoryName);
  };

  const groupByDistinctValue = (arr: Accessory[]) => {
    const distinctValues = [
      ...new Set(arr.map((item) => item.accessoryCategoryName)),
    ];
    const groupedArray = distinctValues.map((value) => {
      return {
        accessoryCategoryName: value,
        items: arr.filter((item) => item.accessoryCategoryName === value),
      } as GroupAccessories;
    });
    return groupedArray;
  };

  const getAccessories = (category: string) => {
    const allAccessories = accessoriesLoadable.getValue() || [];
    if (category === 'All') return allAccessories;

    const groupAccByCategory = groupByDistinctValue(allAccessories);
    const foundData = groupAccByCategory?.find((x) => {
      return x.accessoryCategoryName === category;
    });
    return foundData?.items || [];
  };

  return (
    <div className={styles.mainpage}>
      <h1 className={styles.accessoriesHeading}>{state.heading}</h1>

      <div className={styles.accessoryPacksContent}>
        <div>
          <div
            className={styles.accessoriesSubHeading}
            dangerouslySetInnerHTML={{ __html: state.subHeading }}
          />

          {!!groupAccessories?.length && (
            <div className={styles.accessoriesTabCategory}>
              <div>
                {groupAccessories?.map((ac) => (
                  <TabButton
                    key={ac.accessoryCategoryName}
                    className={styles.tabCategory}
                    value={ac.accessoryCategoryName}
                    onClick={() => handleSelectCategory(ac)}
                    active={ac.accessoryCategoryName === selectedCategory}
                  />
                ))}
              </div>
            </div>
          )}

          <p className={styles.accessoryPackInnerTitle}>
            {state.accessoryPackTitleOverride || 'Accessory Packs'}
          </p>
          {accessoryPacksLoadable.state === 'loading' ? (
            <Loading height="500px" />
          ) : accessoryPacksLoadable.state === 'hasValue' ? (
            <AccessoryPacksCarousel
              accessoryPacks={accessoryPacksLoadable.getValue() ?? []}
            />
          ) : (
            <div className={styles.accessoryLoadError}>
              <p>
                Sorry, we encountered an error while loading the accessory
                packs.
                <span onClick={refreshAccessoryPacks}>
                  Please click here to try again.
                </span>
              </p>
            </div>
          )}
        </div>
      </div>

      <div className={styles.accessoriesContent}>
        <div className="container">
          <p className={styles.accessoryInnerTitle}>Individual Accessories</p>
          {accessoriesLoadable.state === 'loading' ? (
            <Loading height="500px" />
          ) : accessoriesLoadable.state === 'hasValue' ? (
            <div className={styles.accessoriesGrid}>
              {getAccessories(selectedCategory).map((acc, index) => (
                <AccessoryCard
                  key={acc.id}
                  delay={(index + 1) * 100}
                  {...acc}
                />
              ))}
            </div>
          ) : (
            <div className={styles.accessoryLoadError}>
              <p>
                Sorry, we encountered an error while loading the accessories.
                <span onClick={refreshAccessories}>
                  Please click here to try again.
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
