import React from 'react';
import { Card, Button } from '@ui-elements';
import { money } from 'common/utils/formatters';
import { Accessory } from '~/common/models';
import styles from './index.module.scss';

interface AccessoryCardProp extends Accessory {
  delay?: number;
  backgroundColor?: string;
}

export const AccessoryCard = React.memo<AccessoryCardProp>(
  ({ backgroundColor, ...prop }) => {
    const formatAccessoryName = (name: string) => {
      return name.replace(/\s+/g, '-').toLowerCase();
    };

    const getURL = React.useCallback(() => {
      if (!!prop.accessoryCategoryName) {
        const formattedAccName = formatAccessoryName(prop.description);
        const formattedAccCategory = formatAccessoryName(
          prop.accessoryCategoryName
        );

        const pathnames = window.location.pathname.split('/');
        return pathnames.includes('accessories')
          ? `/${pathnames[1]}/${pathnames[2]}/${formattedAccCategory}/${formattedAccName}`
          : `/${pathnames[1]}/accessories/${formattedAccCategory}/${formattedAccName}`;
      }

      return '#';
    }, []);

    return (
      <div
        className={styles.accessory}
        style={{ animationDelay: `${prop.delay}ms` }}
      >
        <Card
          className={styles.accessoryCard}
          contentClassName={styles.accessoryCardContent}
        >
          <div className={styles.imageWrapper}>
            <img src={prop.imageSrc} alt={prop.description} />
          </div>
          <div className={styles.detailsWrapper} style={{ backgroundColor }}>
            <div>
              <p className={styles.name}>{prop.description}</p>
              <p className={styles.price}>
                Fitted RRP: {money(prop.priceFitted)}
              </p>
            </div>
            <Button
              buttonSize="small"
              className={styles.moreInfoButton}
              href={getURL()}
            >
              More info
            </Button>
          </div>
        </Card>
      </div>
    );
  }
);
