import React from 'react';
import cn from 'classnames';
import Carousel from 'react-multi-carousel';
import { SvgIcon } from '@ui-elements';
import { AccessoryPack } from '~/common/models';
import { AccessoryPackItem } from '../accessory-packs-card';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import styles from './index.module.scss';

type CarouselOption = {
  percentage: number;
  centerMode: boolean;
  hasPrev?: boolean;
  hasNext?: boolean;
};

interface AccessoryPacksCarouselProps {
  accessoryPacks: AccessoryPack[];
  cardsColor?: string;
}

export const AccessoryPacksCarousel = React.memo<AccessoryPacksCarouselProps>(
  ({ accessoryPacks, cardsColor }) => {
    const { isDesktop } = useIsMobile();
    const itemsCount = accessoryPacks?.length ?? 3;
    const centerMode = React.useMemo(() => itemsCount < 3, []);

    return (
      <div className={styles.accessoryPacks}>
        <Carousel
          arrows
          swipeable
          centerMode={isDesktop() ? centerMode : false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          containerClass={cn(
            styles.containerClass,
            centerMode && styles.centerMode
          )}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: centerMode ? itemsCount : 3,
              slidesToSlide: 3,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1,
            },
          }}
        >
          {accessoryPacks?.map((accBundle) => (
            <AccessoryPackItem
              key={accBundle.pimIdentifier}
              backgroundColor={cardsColor}
              {...accBundle}
            />
          ))}
        </Carousel>
      </div>
    );
  }
);

type CarouselArrow = {
  type: 'left' | 'right';
  onClick: () => void;
};
const CarouselArrow = React.memo<CarouselArrow>(({ type, onClick }) => (
  <div onClick={onClick} className={styles[`carouselArrow_${type}`]}>
    {type === 'right' ? (
      <SvgIcon size={2} type="chevronRight" />
    ) : (
      <SvgIcon size={2} type="chevronLeft" />
    )}
  </div>
));

interface ArrowsProps {
  onClick?: () => void;
}
const CustomLeftArrow = ({ onClick }: ArrowsProps) => {
  return (
    <SvgIcon
      className={styles.customLeftArrow}
      type="chevronLeft"
      size={2}
      onClick={onClick}
    />
  );
};

const CustomRightArrow = ({ onClick }: ArrowsProps) => {
  return (
    <SvgIcon
      className={styles.customRightArrow}
      type="chevronRight"
      size={2}
      onClick={onClick}
    />
  );
};
