import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { Loading } from '@ui-elements';
import { money } from '~/common/utils/formatters';
import { Accessory } from '~/common/models';
import {
  ModelAccessoriesPage,
  AccessoryRouteBreadcrumbs,
  ModelAccessoriesQuery,
} from '../../store';
import { AccessoryCard } from '../accessory-card';
import styles from './index.module.scss';

export const AccessoryDetailsPage = React.memo(() => {
  const history = useHistory();

  const [_, setBreadcrumbs] = useRecoilState(AccessoryRouteBreadcrumbs);
  const [{ accessory, pimIdentifier }] = useRecoilState(ModelAccessoriesPage);
  const accessories = useRecoilValueLoadable(
    ModelAccessoriesQuery(pimIdentifier)
  );

  const videoRef = useRef<ReactPlayer>(null);
  const [isPlayVideo, setPlayVideo] = useState<boolean>(false);
  const [relatedAccessories, setRelatedAccessories] = useState<Accessory[]>([]);
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    if (accessories.state === 'hasValue') {
      const contents = accessories.getValue();
      const relatedAccs = getRandomItem(
        contents.filter(
          (c) => c.accessoryCategoryName === accessory?.accessoryCategoryName
        ),
        4
      );

      setRelatedAccessories(relatedAccs);
      // setBreadcrumbs([
      //   {
      //     path: `/#`,
      //     label: 'Accessories',
      //     onClick: () => history.replace('/accessories'),
      //   },
      //   { path: '#', label: accessory?.description },
      // ]);
    }
  }, [accessories.state]);

  useEffect(() => {
    if (!!accessory) {
      const imgs: string[] = [];
      if (!!accessory.imageSrc) imgs.push(accessory.imageSrc);
      if (
        !!accessory.detailImageSrc &&
        !accessory.detailImageSrc.endsWith('photo-not-avail.jpg')
      )
        imgs.push(accessory.detailImageSrc);
      if (
        !!accessory.image360Src &&
        !accessory.image360Src.endsWith('photo-not-avail.jpg')
      )
        imgs.push(accessory.image360Src);
      setImages(imgs);
    }
  }, [accessory]);

  const handleVideoEnded = () => {
    setPlayVideo(false);
    if (!!videoRef.current) {
      videoRef.current.seekTo(0);
    }
  };

  const getRandomItem = (originalArray: Accessory[], n: number) => {
    const newArray: Accessory[] = [];
    for (let i = 0; i < n; i++) {
      const randomIndex = Math.floor(Math.random() * originalArray.length);
      const selectedItem = originalArray.splice(randomIndex, 1)[0];
      if (!!selectedItem) {
        newArray.push(selectedItem);
      }
    }
    return newArray;
  };

  return accessories.state === 'hasValue' && accessory ? (
    <div>
      <div className={styles.accessoryContent}>
        <div className={styles.accessoryImageWrapper}>
          <Carousel
            autoPlay={false}
            swipeable={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            showIndicators={false}
            className={styles.accessoryImageCarousel}
          >
            {images.map((img, index) => (
              <img
                key={index}
                className={styles.imageSrc}
                alt={accessory.description}
                src={img}
              />
            ))}
          </Carousel>
        </div>
        <div className={styles.accessoryDetailsWrapper}>
          <div>
            <h2 className={styles.description}>{accessory.description}</h2>
            <p className={styles.price}>
              Fitted RRP: {money(accessory.priceFitted)}
            </p>
            <div
              className={styles.longDescription}
              dangerouslySetInnerHTML={{
                __html: accessory.longDescription || '',
              }}
            />
            <div
              className={styles.priceDisclaimer}
              dangerouslySetInnerHTML={{
                __html: accessory.tooltipDisclaimer || '',
              }}
            />
            <div
              className={styles.priceDisclaimer}
              dangerouslySetInnerHTML={{
                __html: accessory.longDisclaimer || '',
              }}
            />
          </div>
        </div>
      </div>

      {!!accessory.videoSrc && (
        <div className={styles.accessoryVideo}>
          <ReactPlayer
            width="100%"
            height="100%"
            pip={false}
            ref={videoRef}
            className={styles.videoContent}
            url={accessory.videoSrc}
            onEnded={handleVideoEnded}
            playing={isPlayVideo}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  disablekb: 1,
                  autoplay: 0,
                  controls: 0,
                  modestbranding: 1,
                },
              },
            }}
          />
        </div>
      )}

      {relatedAccessories?.length !== 0 && (
        <div className="container">
          <div className={styles.relatedAccessories}>
            <div className={styles.relatedAccessoriesTitle}>
              <p>Related Products</p>
            </div>
            <div
              className={styles.relatedAccessoriesGrid}
              style={{
                gridTemplateColumns: `repeat(${relatedAccessories.length}, 1fr)`,
              }}
            >
              {relatedAccessories.map((acc) => (
                <AccessoryCard key={acc.id} {...acc} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Loading />
  );
});
